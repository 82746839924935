<template>
  <div>
     <div class="dispflex brdcrumbscont text-center pl-3 pr-3" style="margin-top: 4%;background: #E1E1E1;">
   
       <div >
         <ul class="breadcrumb" >
          <li><a href="#"><i class="fas fa-home" ></i></a></li>
          <li><a href="javascript:void(0);" @click="goToSchoolList">School List</a></li>
          <li>School View</li>
        </ul>
       </div>
      </div>
  <div class="vi-school-overview">
    <div v-if="schoolData">
      <!-- ======= Search Section ======= -->
      <div class="web-resfound p-2">
        <button class="btn web-backbtn" @click.prevent="viewSchoolAction">
          <v-icon name="arrow-left" />
          <span class="bck" style="display:none">Back</span>
        </button>
        <div class="container ">
          <div class="row">
            <div class="col-md-3 text-center mt-3">
               <img style="width:80%" v-if="schoolData.image && schoolData.image.path"
                        :src="schoolData.image.path"
                        :alt="schoolData.accountName"
                        :title="schoolData.accountName"
                />
                <img v-else
                  :src="schoolData.image"
                  :alt="schoolData.accountName"
                  :title="schoolData.accountName"
                />
             
            </div>
            <div class="col-md-9 web-schnamecont mt-md-3 mt-sm-3">
              <h4>
                <strong
                  >{{ schoolData.accountName }}</strong
                >
              </h4>
              <p>
                <span
                  ><i
                    class="fa fa-map-marker fa-lg"
                    aria-hidden="true"
                  ></i></span
                >{{ schoolData.street ? schoolData.street : '' }},{{ schoolData.city ? schoolData.city : '' }},{{ schoolData.district ? schoolData.district : '' }},{{ schoolData.state ? schoolData.state : '' }},{{ schoolData.pincode ? schoolData.pincode : '' }}
              </p>
              <p>
                <span><i class="fa fa-envelope" aria-hidden="true"></i></span
                ><a>{{ schoolData.email }}</a>
              </p>
              <p>
                <span
                  ><i class="fa fa-phone-square" aria-hidden="true"></i></span
                >{{ schoolData.landlineNo ? schoolData.landlineNo : ''  }}
              </p>
              <div class="web-admbtn"  >
                <a
                  class="btn web-custbtn web-rounded-xl h-100 btn-3 "
                  style="width:225px"
                  >{{ checkAdmissionAuvaiable > 0 ? 'Admission Open Now' : 'Admission Closed' }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="web-filterbg pl-3 pr-3 m-0" id="filtersect">
        <div class="container-fluid">
          <div class="row">
            <div class="offset-md-3 web-mobnav">
              <nav class=" vertical-align-middle scroll ml-2">
                <ul
                  v-scroll-spy-active="{ class: 'tabactive' }"
                  v-scroll-spy-link
                >
                  <li><a>Overview</a></li>
                  <li ><a>Seat Availability</a></li>
                   <!-- <li v-if="schoolItem.applySchool.length > 0 && !checkApplied(schoolItem.applySchool)" v-else><a>Applied Class</a></li> -->
                  <li ><a>Admission Process</a></li>
                  <li><a>Facilities</a></li>
                  <li><a>Contact Details</a></li>
                   <li><a>Gallery</a></li>
                  <li><a>Rating & Reviews</a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="web-innerpagecont py-3 pl-3 pr-3">
        <div class="container-fluid">
          <div class="row web-row-flex">
            <div class="col-md-3 col-sm-6">
              <p><strong>Place for Google ad</strong></p>
            </div>
            <div class="col-md-9 col-sm-6">
              <div v-scroll-spy="{ data: 'section', offset: 120 }">
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection">
                      <img
                        src="../../assets/img/school_info_icon.svg"
                        width="99%"
                        alt="Overview"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Overview</h1>
                    </div>
                  </div>
                  <div class="p-3">
                   
                    <p class="mt-2">
                      {{ schoolItem.overView }}
                    
                    </p>
                   
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp"  >
                  <div class="web-headercont">
                    <div class="web-iconsection">
                      <img
                        src="../../assets/img/seatsavailicn.svg"
                        width="99%"
                        alt="Seats Availability"
                        title="Seats Availability"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Seats Availability</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Class Details</th>
                          <th scope="col">No. of Seats</th>
                          <th scope="col">No. of Seats Available</th>
                          <th scope="col">Admission Status</th>
                          <th scope="col">Annual Fees</th>
                          <th scope="col">Online</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  v-for="(fee, index) in admissionDetails" :key="index">
                          <td v-if="fee.isShowSearch" data-label="Class Details">{{ fee.class ? fee.class.className : '' }}</td>
                          <td v-if="fee.isShowSearch" data-label="No. of Seats">{{ fee.noOfSeats }}</td>
                           <td v-if="fee.isShowSearch" data-label="No. of Seats">{{ fee.seatsAvailable }}</td>
                           <td v-if="fee.isShowSearch" data-label="No. of Seats">{{ fee.admissionStatus }}</td>
                          <td v-if="fee.isShowSearch" data-label="Total Fees">{{ fee.amount }} </td>
                          <td v-if="fee.isShowSearch" data-label="Apply Now" >
                            <a  href="javascript:void();" class="web-applynow" v-if="fee.amount && fee.admissionStatus == 'Open' " @click.prevent="applyAdmissionOnline(index)">
                              <img 
                                style="border-radius: 30px;"
                                src="../../assets/img/handcursoricn.svg"
                                
                                alt="apply now"
                                title="Apply Now"
                              />
                            </a>
                             <a  href="javascript:void();" class="web-notapply" v-else >
                             <img 
                                style="cursor: not-allowed;border-radius: 30px;"
                                src="../../assets/img/notapply.svg"
                               
                                alt="Not Available"
                                title="Not Available"
                              />
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                 <!-- <div class="web-schovw_whitebg mb-5 wow web-fadeInUp" v-else>
                  <div class="web-headercont"> 
                    <div class="web-iconsection">
                      <img
                        src="../../assets/img/seatsavailicn.svg"
                        width="99%"
                        alt="Seats Availability"
                        title="Seats Availability"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Applied Class</h1>
                    </div>
                  </div>
                  <div class="p-3">
                  Class Name: {{ schoolItem.applySchool[0].className }}
                  </div>
                </div> -->
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/admissionicn.svg"
                        width="99%"
                        alt="Vidhyaan Admission Process"
                        title="Vidhyaan Admission Process"
                      />
                    </div>
                    <div class="web-titlesection" >
                      <h1>Admission Process</h1>
                    </div>
                  </div>
                  <div class="p-3" >
                    <label class="web-custradio"
                      >Online Admission
                      <input 
                        type="radio"
                        name="admission"
                        id="admon"
                        v-model="admissionSelect"
                        value="aOn"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                    <label class="web-custradio"
                      >Offline Admission
                      <input
                        type="radio"
                        name="admission"
                        id="admoff"
                        v-model="admissionSelect"
                        value="aOff"
                      />
                      <span class="web-checkmark"></span>
                    </label>
                    <div
                      id="offlineadm"
                      v-show="admissionSelect === 'aOff'"
                      style="display:none;padding:8px;background:#efefef;"
                      class="mt-3"
                    >
                      Click on <a href="#contactdet">Contact School</a> for your
                      Offline admission.
                    </div>

                    <!-- <form
                      action=""
                      method="post"
                      accept-charset="utf-8"
                      id="onlineadm"
                      style="display:none;"
                      v-show="admissionSelect === 'aOn'"
                    >
                      <div class="web-admtitle">Student Details</div>
                      <div class="form-row">
                        <div class="form-group input-group col-md-6 pr-md-3">
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="clsapp"
                              value="Pre-KG"
                              class="form-control web-form-input"
                              id="clsappid"
                              placeholder="Class Applied"
                              required="required"
                            />
                            <label for="clsappid"
                              >Class Applied
                              <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 ">
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="enrlstatus"
                              readonly
                              value="Form Fill"
                              class="form-control web-form-input "
                              id="enrlstatusid"
                              placeholder="Enrolment Status"
                              required="required"
                            />
                            <label for="enrlstatusid" class="web-blink"
                              >Enrolment Status
                              <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="col-md-12 text-right">
                          <button
                            onclick="feestruc()"
                            class="btn web-btnsml web-stwidth m-2"
                          >
                            Proceed to Payment Process
                          </button>
                        </div>
                        <div class="form-group input-group col-md-12 mt-3">
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="admstatus"
                              readonly
                              value="Open"
                              class="form-control web-form-input"
                              id="admstatusid"
                              placeholder="Admission Status"
                              required="required"
                            />
                            <label for="admstatusid"
                              >Admission Status
                              <span class="required">*</span></label
                            >
                          </span>
                        </div>

                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="stfname"
                              value=""
                              class="form-control web-form-input"
                              id="stfnameid"
                              placeholder="First Name"
                              required="required"
                            />
                            <label for="stfnameid"
                              >First Name <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 mt-3">
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="stlname"
                              value=""
                              class="form-control web-form-input"
                              id="stlnameid"
                              placeholder="Last Name"
                              required="required"
                            />
                            <label for="stlnameid"
                              >Last Name <span class="required">*</span></label
                            >
                          </span>
                        </div>
                      </div>
                      <div class="form-row mt-4">
                        <div class="form-group input-group col-md-6 pr-md-3 ">
                          <span class="web-has-float-label">
                            <input
                              type="date"
                              onkeydown="return false" 
                              name="date"
                              value=""
                              class="form-control web-form-input"
                              id="bdate"
                              placeholder="Date of Birth"
                              required="required"
                            />
                            <label for="bdate"
                              >Date of Birth
                              <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 ">
                          <span class="web-has-float-label" style="flex-grow:0">
                            <label for="gender">Gender</label>
                          </span>
                          <div
                            style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                          >
                            <label class="web-custradio" style="margin-bottom:0"
                              >Male
                              <input
                                type="radio"
                                name="admission"
                                checked="checked"
                              />
                              <span class="web-checkmark"></span>
                            </label>
                            <label class="web-custradio" style="margin-bottom:0"
                              >Female
                              <input type="radio" name="admission" />
                              <span class="web-checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="web-admtitle">Parent Details</div>
                      <div class="form-row">
                        <div class="form-group input-group col-md-6 pr-md-3">
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="pfname"
                              value=""
                              class="form-control web-form-input"
                              id="pfnameid"
                              placeholder="First Name"
                              required="required"
                            />
                            <label for="pfnameid"
                              >First Name <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6">
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="plname"
                              value=""
                              class="form-control web-form-input"
                              id="plnameid"
                              placeholder="Last Name"
                              required="required"
                            />
                            <label for="plnameid"
                              >Last Name <span class="required">*</span></label
                            >
                          </span>
                        </div>
                      </div>
                      <div class="form-row">
                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="web-has-float-label">
                            <input
                              list="relation"
                              value=""
                              class="form-control web-form-input"
                              id="selrelid"
                              placeholder="Select District"
                              required="required"
                            />
                            <label for="selrelid"
                              >Select Relation
                              <span class="required">*</span></label
                            >
                            <datalist id="relation">
                              <option value="Mother">Mother</option>
                              <option value="Father">Father</option>
                              <option value="Guardian">Guardian</option>
                            </datalist>
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 mt-3">
                          <span class="web-has-float-label">
                            <input
                              list="occupation"
                              value=""
                              class="form-control web-form-input"
                              id="seloccid"
                              placeholder="Select District"
                              required="required"
                            />
                            <label for="seloccid"
                              >Select Occupation
                              <span class="required">*</span></label
                            >
                            <datalist id="occupation">
                              <option value="Private">Private</option>
                              <option value="Govt.">Govt.</option>
                              <option value="Business">Business</option>
                              <option value="Others">Others</option>
                            </datalist>
                          </span>
                        </div>
                      </div>
                      <div class="web-admtitle">Contact Details</div>
                      <div class="form-row">
                        <div class="form-group input-group col-md-12 ">
                          <span class="web-has-float-label">
                            <input
                              type="email"
                              name="semail"
                              value=""
                              class="form-control web-form-input"
                              id="semailid"
                              placeholder="First Name"
                              required="required"
                            />
                            <label for="semailid"
                              >Email <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="mobnum1"
                              value=""
                              class="form-control web-form-input"
                              id="mobnum1id"
                              placeholder="Mobile #1"
                              required="required"
                            />
                            <label for="mobnum1id"
                              >Mobile #1 <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 mt-3">
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="mobnum2"
                              value=""
                              class="form-control web-form-input"
                              id="mobnum2id"
                              placeholder="Mobile #1"
                              required="required"
                            />
                            <label for="mobnum2id"
                              >Mobile #2 <span class="required">*</span></label
                            >
                          </span>
                        </div>

                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="adr1"
                              value=""
                              class="form-control web-form-input"
                              id="adr1id"
                              placeholder="Mobile #1"
                              required="required"
                            />
                            <label for="adr1id"
                              >Address Line 1
                              <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 mt-3">
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="adr2"
                              value=""
                              class="form-control web-form-input"
                              id="adr2id"
                              placeholder="Mobile #1"
                              required="required"
                            />
                            <label for="adr2id"
                              >Address Line 2
                              <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="web-has-float-label">
                            <input
                              list="stateprv"
                              value=""
                              class="form-control web-form-input"
                              id="selstatprvid"
                              placeholder="Select State / Province"
                              required="required"
                            />
                            <label for="selstatprvid"
                              >Select State / Province
                              <span class="required">*</span></label
                            >
                            <datalist id="stateprv">
                              <option value="Tamilnadu">Tamilnadu</option>
                            </datalist>
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 mt-3">
                          <span class="web-has-float-label">
                            <input
                              list="citydist"
                              value=""
                              class="form-control web-form-input"
                              id="selcitydistid"
                              placeholder="Select City / District"
                              required="required"
                            />
                            <label for="selcitydistid"
                              >Select City / District
                              <span class="required">*</span></label
                            >
                            <datalist id="citydist">
                              <option value="Coimbatore">Coimbatore</option>
                            </datalist>
                          </span>
                        </div>
                        <div
                          class="form-group input-group col-md-6 pr-md-3 mt-3"
                        >
                          <span class="web-has-float-label">
                            <input
                              type="text"
                              name="pstcde"
                              value=""
                              class="form-control web-form-input"
                              id="pcid"
                              placeholder="Postal Code"
                              required="required"
                            />
                            <label for="pcid"
                              >Postal Code
                              <span class="required">*</span></label
                            >
                          </span>
                        </div>
                        <div class="form-group input-group col-md-6 mt-3">
                          <span class="web-has-float-label">
                            <input
                              list="countrylist"
                              value=""
                              class="form-control web-form-input"
                              id="countrylistid"
                              placeholder="Select Country"
                              required="required"
                            />
                            <label for="countrylistid"
                              >Select Country
                              <span class="required">*</span></label
                            >
                            <datalist id="countrylist">
                              <option value="India">India</option>
                            </datalist>
                          </span>
                        </div>
                      </div>
                      <div class="web-admtitle">Attachment Proof</div>
                      <div class="form-row">
                        <div class="form-group input-group col-md-12 mt-3">
                          <span class="web-has-float-label">
                            <input
                              list="reqproof"
                              value=""
                              class="form-control web-form-input"
                              id="reqproofid"
                              placeholder="Select Country"
                              required="required"
                            />
                            <label for="reqproofid"
                              >Required Proof
                              <span class="required">*</span></label
                            >
                            <datalist id="reqproof">
                              <option value="Aadhaar Card">Aadhaar Card</option>
                              <option value="Passport Size Photo"
                                >Passport Size Photo</option
                              >
                              <option value="Address Proof"
                                >Address Proof</option
                              >
                            </datalist>
                          </span>
                        </div>
                        <div class="form-group input-group col-md-12 mt-2">
                          <div class=" web-photobrd ">
                            <div
                              class="card card-block p-3 w-100 mx-auto web-custbtn text-center uploadphoto"
                            >
                              <input
                                type="file"
                                accept="image/*"
                                class="web-myfile"
                                id="web-myfile"
                                onchange="preview_image(event)"
                              />
                              <i
                                class="fa fa-upload fa-2x"
                                for="web-myfile"
                                aria-hidden="true"
                              ></i>
                              Upload Proof
                            </div>
                          </div>
                          <div class="web-atch">
                            <table>
                              <thead>
                                <tr>
                                  <th scope="col">Image</th>
                                  <th scope="col">File Type</th>
                                  <th scope="col">Delete / Download</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td data-label="Image">
                                    <img id="output_image" width="40" />
                                  </td>
                                  <td data-label="File Type">
                                    Aadhaar Card
                                  </td>
                                  <td data-label="Delete / Download">
                                    <button class="btn" style="padding:0">
                                      <img
                                        width="32"
                                        src="../../assets/img/delete_icon.svg"
                                        alt="Delete"
                                        title="Delete"
                                      />
                                    </button>
                                    <button class="btn" style="padding:0">
                                      <img
                                        width="26"
                                        src="../../assets/img/download_icon.svg"
                                        alt="Download"
                                        title="Download"
                                      />
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="mx-auto text-center">
                        <button type="submit" class="web-custbutton mt-3">
                          Submit
                        </button>
                        <button type="submit" class="web-cancelbutton mt-3 ml-2">
                          Cancel
                        </button>
                      </div>
                    </form> -->
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/facilitiesicn.svg"
                        width="99%"
                        alt="Facilities"
                        title="Facilities"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Facilities</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <p>
                      The school facility consists of not just the physical
                      structure such as plumbing, mechanical, electrical,
                      telecommunications, security, fire prevention and
                      suppression systems, and the various building systems, but
                      it also includes furnishing, materials and supplies,
                      equipment and information technology. Basic facilities we
                      have in our schools like,
                    </p>
                    <div class="web-dispflex">
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/school_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Type of School</p>
                          <span>{{ schoolData.schoolType }}</span>
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/afflilation_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Examination Board</p>
                          <span>{{ schoolData.affilliateIdToBoard | capitalize }}</span>
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/fees_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Annual Fees</p>
                          <span
                            >Start from Rs. {{ schoolData.lowestFees }}</span
                          >
                        </span>
                      </div>
                      <div class="web-colwidth mb-4 colcent">
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            src="../../assets/img/lang_icn.png"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>Language of Instruction</p>
                          <span>{{ schoolData.medium }}</span>
                        </span>
                      </div>
                      <div
                        class="web-colwidth mb-4 colcent"
                        v-for="(fitem, index) in schoolData.facilities"
                        :key="index"
                      >
                        <span class="web-facicn">
                          <img
                            class="img-fluid"
                            :src="`${facilitiesURL}/${fitem.icon}.svg`"
                            width="56"
                          />
                        </span>
                        <span class="web-factxt">
                          <p>{{ fitem.name }}</p>
                          <span>Yes</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:6px">
                      <img
                        src="../../assets/img/rs.png"
                        width="99%"
                        alt="Fee Structure"
                        title="Fee Structure"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Fee Structure</h1>
                    </div>
                  </div>
                  <div class="p-3 web-feetable">
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Payment Type</th>
                          <th scope="col">Fees in [RS]</th>
                          <th scope="col" style="text-align:left">
                            Payment Status
                          </th>
                          <th scope="col" style="text-align:left">
                            Choose Option
                          </th>
                          <th scope="col">Print / Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td data-label="Payment Type">Full Term</td>
                          <td data-label="Fees in [RS]">12,000</td>
                          <td data-label="Payment Status" class="failed">
                            <i class="fas fa-circle"></i> Transaction Failed
                          </td>
                          <td data-label="Choose Option">
                            <label class="web-custcheckbox">
                              <input type="checkbox" />
                              <span class="web-checkmarkchk"></span>
                            </label>
                          </td>
                          <td data-label="Print / Download">
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/print_icon.svg"
                                alt="Print"
                                title="Print"
                              />
                            </button>
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/download_icon.svg"
                                alt="Download"
                                title="Download"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td data-label="Payment Type">First Term</td>
                          <td data-label="Fees in [RS]">5,000</td>
                          <td data-label="Payment Status" class="paid">
                            <i class="fas fa-circle"></i> Received
                          </td>
                          <td data-label="Choose Option">
                            <label class="web-custcheckbox">
                              <input type="checkbox" checked />
                              <span class="web-checkmarkchk"></span>
                            </label>
                          </td>
                          <td data-label="Print / Download">
                            <button class="btn" style="padding:0">
                              <img
                                width="26"
                                src="../../assets/img/print_icon.svg"
                                alt="Print"
                                title="Print"
                              />
                            </button>
                            <button class="btn" style="padding:0">
                              <img
                                width="26"
                                src="../../assets/img/download_icon.svg"
                                alt="Download"
                                title="Download"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td data-label="Payment Type">Second Term</td>
                          <td data-label="Fees in [RS]">5,000</td>
                          <td data-label="Payment Status" class="notpaid">
                            <i class="fas fa-circle"></i> Not Received
                          </td>
                          <td data-label="Choose Option">
                            <label class="web-custcheckbox">
                              <input type="checkbox" disabled />
                              <span class="web-checkmarkchk"></span>
                            </label>
                          </td>
                          <td data-label="Print / Download">
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/print_icon.svg"
                                alt="Print"
                                title="Print"
                              />
                            </button>
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/download_icon.svg"
                                alt="Download"
                                title="Download"
                              />
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td data-label="Payment Type">Third Term</td>
                          <td data-label="Fees in [RS]">5,000</td>
                          <td data-label="Payment Status" class="notpaid">
                            <i class="fas fa-circle"></i> Not Received
                          </td>
                          <td data-label="Choose Option">
                            <label class="web-custcheckbox">
                              <input type="checkbox" disabled />
                              <span class="web-checkmarkchk"></span>
                            </label>
                          </td>
                          <td data-label="Print / Download">
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/print_icon.svg"
                                alt="Print"
                                title="Print"
                              />
                            </button>
                            <button class="btn" style="padding:0" disabled>
                              <img
                                width="26"
                                src="../../assets/img/download_icon.svg"
                                alt="Download"
                                title="Download"
                              />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="col-md-12 text-right">
                      <button
                        type="button"
                        class="btn web-btnsml web-stwidth mt-3"
                        disabled
                      >
                        Proceed to Payment Gateway
                      </button>
                    </div>
                  </div>
                </div> -->
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/contactdetailsicon.svg"
                        width="99%"
                        alt="Contact Details"
                        title="Contact Details"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Contact Details</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="form-row">
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="schoolItem.email">
                        <span class="web-has-float-label" style="flex-grow:0">
                          <label for="email">Email</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          <a href="mailto:info@velammal.org"
                            >{{ schoolItem.email }}</a
                          >
                        </div>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3" v-if="schoolItem.landlineNo">
                        <span class="web-has-float-label" style="margin:1px">
                          <label for="phno">Phone #</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          +91 {{ schoolItem.landlineNo }}
                        </div>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="schoolItem.website">
                        <span class="web-has-float-label" style="flex-grow:0">
                          <label for="website">Website</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          <a
                            href="http://www.velammal.org/velammal-vidhyashram-mambakkam/"
                            target="_blank"
                            >{{ schoolItem.website || '' }}</a
                          >
                        </div>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3" v-if="schoolItem.location">
                        <span class="web-has-float-label" style="margin:1px">
                          <label for="location">Location</label>
                        </span>
                        <div
                          style="padding-top:9px;width:100%;border-bottom:1px solid #e5e5e5"
                        >
                          {{ schoolItem.location }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                 <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <!--<img
                        src="../../assets/img/contactdetailsicon.svg"
                        width="99%"
                        alt="Contact Details"
                        title="Contact Details"
                      />-->
                      <i class="fas fa-images" style="font-size:2rem;color:#fff"></i>
                    </div>
                    <div class="web-titlesection">
                      <h1>Gallery</h1>
                    </div>
                  </div>
                  <div class="p-3">
                      <div class="row">
                            <div style="background-color: darkgrey;margin-left: 1%;" class="col-lg-2" v-for="(item, index) in galleryList" :key="index">
                               <img
                                :src="item.path"
                                alt="Avatar"
                                class="mt-3"
                                width="150"
                                height="150"
                                />
                            
                            </div>
                        </div>
                  </div>
                </div>
                <div class="web-schovw_whitebg mb-5 wow web-fadeInUp">
                  <div class="web-headercont">
                    <div class="web-iconsection" style="padding:10px">
                      <img
                        src="../../assets/img/reviewicn.svg"
                        width="99%"
                        alt="Ratings & Reviews"
                        title="Ratings & Reviews"
                      />
                    </div>
                    <div class="web-titlesection">
                      <h1>Ratings & Reviews</h1>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="web-dispflex rating">
                      <div class="web-colwidth">
                        <p><strong>Review by Parents</strong></p>
                        <hr />
                        <div class="web-dispflex">
                          <div>
                            <p>
                              <label>Infrastructure: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.infrastructure"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Academics: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.academics"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Sports: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.sports"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Faculty: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.faculty"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Safety: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :rating="schoolData.ratings.safety"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                          </div>
                          <div class="web-overall">
                            <p>{{ schoolData.ratings | averageRating }}</p>
                            <p>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.5"
                                :rating="schoolData.ratings | averageRating"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>(Over All)</p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="web-colwidth">
                        <p><strong>Review by Vidhyaan</strong></p>
                        <hr />
                        <div class="web-dispflex">
                          <div>
                            <p>
                              <label>Infrastructure: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Academics: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Sports: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Faculty: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>
                              <label>Safety: </label>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                          </div>
                          <div class="web-overall">
                            <p>4.0</p>
                            <p>
                              <star-rating
                                :star-size="20"
                                :show-rating="false"
                                inactive-color="#666"
                                active-color="#ffa500"
                                :increment="0.1"
                                :read-only="true"
                                class="vi-r-review"
                              />
                            </p>
                            <p>(Over All)</p>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="web-dispflex mt-3">
                      <div class="web-colwidth rating" style="border:0">
                        <h3>Write a Review</h3>
                        <span class="vi-error" v-if="rateError"
                          >Please rate all catocary</span
                        >
                        <!-- <p>
                          <label>Over All: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            class="vi-w-review"
                          />
                        </p> -->
                        <p>
                          <label>Infrastructure: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="
                              setRating($event, 'infrastructure')
                            "
                            class="vi-w-review"
                            v-model="infrastructure"
                          />
                        </p>
                        <p>
                          <label>Academics: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'academics')"
                            class="vi-w-review"
                            v-model="academics"
                          />
                        </p>
                        <p>
                          <label>Sports: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'sports')"
                            class="vi-w-review"
                            v-model="sports"
                          />
                        </p>
                        <p>
                          <label>Faculty: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'faculty')"
                            class="vi-w-review"
                            v-model="faculty"
                          />
                        </p>
                        <p>
                          <label>Safety: </label>
                          <star-rating
                            :star-size="20"
                            inactive-color="#666"
                            active-color="#ffa500"
                            :increment="0.5"
                            @rating-selected="setRating($event, 'safety')"
                            class="vi-w-review"
                            v-model="safety"
                          />
                        </p>
                      </div>
                      <div class="web-colwidth" style="border:0">
                        <div class="form-row mt-4">
                          <div class="form-group input-group mt-md-3">
                            <span class="web-has-float-label">
                              <textarea
                                class="form-control web-form-input"
                                style="min-height:135px;resize:none"
                                id="message"
                                placeholder="Message"
                                v-model="message"
                              ></textarea>
                              <label
                                v-if="messageError"
                                class="vi-error"
                                for="message"
                                >{{ messageError }}</label
                              >
                              <label for="message" v-else
                                >Message<span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                        <button
                          type="submit"
                          class="web-custbutton mt-3"
                          @click="submitRating"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
  <b-modal id="addstudpop" class="modal" no-close-on-backdrop no-close-on-esc>
      <div class="contact-form">
        <h2>Online Admission</h2>
        <br />
        <div style="height: 350px; overflow-y: auto">
          <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
               <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="studentFirstName"
                    v-model="contactDetails.className"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                
                  <label for="stdFirstName" 
                    >Class Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    v-validate="'required'"
                    name="studentFirstName"
                    v-model="contactDetails.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentFirstName')"
                    class="vi-error"
                    style="top:-1em"
                    >First name is required</label
                  >
                  <label for="stdFirstName" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>

              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    name="studentLastName"
                    v-model="contactDetails.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentLastName')"
                    class="vi-error"
                    style="top:-1em"
                    >Last name is required</label
                  >
                  <label for="stdLastName" v-else
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="stdEmail"
                    v-model="contactDetails.email"
                    class="form-control form-input"
                    id="stdEmail"
                  />
                  <label for="stdEmail"
                    >Secondary Email</label
                  >
                </span>
              </div>
              <!-- <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <select
                    name="status"
                    disabled
                    class="form-control form-input"
                    id="enrollmentStatus"
                    v-model="contactDetails.admissionStatus"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in enrollmentStatusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                   
                 
                  <label for="enrollmentStatus" 
                    >Enrollment Status <span class="required">*</span></label
                  >
                </span>
              </div> -->
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <!-- max="9999-12-31" -->
                <span class="has-float-label">
                  <input
                   v-validate="'required'"
                    type="date"
                    onkeydown="return false"
                    name="DOB"
                    v-model="contactDetails.dob"
                    class="form-control form-input"
                    id="stdDob"
                  />
                  <label
                    v-if="errors.first('studentvalidate.DOB')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of birth is required</label
                  >
                 
                 
                  <label for="stdDob" v-else
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
              
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    v-validate="'required'"
                    name="Blood Group"
                    class="form-control form-input"
                    id="bloodGroup"
                    v-model="contactDetails.bloodGroup"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(blood, index) in bloodGroupList"
                      :key="index"
                      :value="blood"
                    >
                      {{ blood }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('studentvalidate.Blood Group')"
                    class="vi-error"
                    style="top:-1em"
                    >Blood group is required</label
                  >
                  <label for="bloodGroup" v-else
                    >Blood Group <span class="required">*</span></label
                  >
                </span>
              </div>
              <!-- <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Siblings?</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      v-model="contactDetails.siblings"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div> -->

                  <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="contactDetails.gender"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in genderList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.gender')"
                            class="vi-error"
                            style="top:-1em"
                            >Gender is required</label
                          > 
                          <label for="firstName" v-else
                            >Gender <span class="required">*</span></label
                          >
                        </span>
                      </div>
              <fieldset class="accordion-group-container">
                <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    Parent Details
                  </label>
                    <div class="form-row">
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="contactDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.pointOfContact')"
                            class="vi-error"
                            style="top:-1em"
                            >Point of contact is required</label
                          > 
                          <label for="firstName" v-else
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Father'">
                          <input
                            type="text"
                            name="fthrname"
                            disabled
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father Name"
                          />
                          <label for="fthrnameid" 
                            >Father Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Mother'">
                          <input
                            type="text"
                            name="Mother"
                            disabled
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            id="Mother"
                            placeholder="Mother Name"
                          />
                          <label for="fthrnameid" 
                            >Mother Name <span class="required">*</span></label
                          >
                        </span>
                         <span class="has-float-label" v-if="contactDetails.pointOfContact == 'Guardian'">
                          <input
                            type="text"
                            name="Guardian"
                            disabled
                            v-model="userDetails.firstName"
                            class="form-control form-input"
                            id="Guardian"
                            placeholder="Guardian Name"
                          />
                          <label for="Guardian" 
                            >Guardian Name <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            disabled
                            v-model="userDetails.occupation"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Occupation"
                          />
                          <label for="occid"
                            >Occupation </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            disabled
                            v-model="userDetails.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Religion </label
                          >
                        </span>
                        
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            disabled
                            name="nationality"
                            v-model="userDetails.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid"
                            >Nationality </label
                          >
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="email"
                            disabled
                            name="ptemail"
                            v-model="userDetails.email"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                          />
                          <label for="ptemailid" 
                            >Primary Email <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            maxlength="10"
                            disabled
                            name="ptph"
                            v-model="userDetails.phoneNo"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Phone"
                          />
                         
                          <label  for="ptphid">Phone <span class="required">*</span></label>
                        </span>
                      </div>

                      <div class="form-group input-group col-md-6 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="stadr"
                            disabled
                            v-model="userDetails.address"
                            class="form-control form-input"
                            id="stadrid"
                            placeholder="Address Details"
                          />
                          <label for="stadrid"
                            >Address Details</label
                          >
                        </span>
                      </div>
                    </div>
                </div>

                <!-- <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    Candidate Enrollment Details
                  </label>
                  <div class="form-row">
                   
                    <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          name="AcademicYear"
                          disabled
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="contactDetails.academicYear"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in academicYearList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.from }} - {{ item.to }}
                          </option>
                        </select>
                       
                        <label for="acyearid" 
                          >Academic Year <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                      <span class="has-float-label">
                       
                         <select
                          v-validate="'required'"
                          name="Class"
                          class="form-control form-input"
                          id="deptlistid"
                          disabled
                          v-model="contactDetails.class"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in classList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.className }}  
                          </option>
                        </select>
                       
                        <label for="clname" 
                          >Class Name<span class="required">*</span></label
                        >
                      </span>
                    </div>
                  </div>
                </div> -->
              </fieldset>
            </div>
            <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon">
               
              </div>
              <div class="text-right dk_iconsml">
                
               
                 <button 
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="submitApplication"
                >
                    <span>Save</span>
                </button>
                <button type="button" @click.prevent="closePoup" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import "vue-awesome/icons/arrow-left";
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import errorLog from "@/utils/errorLog";
import secureUI from "../../utils/secureUI";
import StarRating from "vue-star-rating";

export default {
  name: "ViParentSchoolOverview",
  components: {
    ViSpinner,
    StarRating,
  },
  props: ["swapComponent"],
  data() {
    return {
      contactDetails: {
        firstName: "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        dateOfLeaving: null,
        pointOfContact: 'Father',
        academicYear: null,
        gender: "Male",
        parentId: null,
        accountId: null,
        email: "",
        mobileOne: 0,
        mobileTwo: 0,
        image: "",
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: 'Submitted',
        siblings: false,
        rollNoAllocated: "",
        class: null,
        classSection: null,
        parentId: null,
        siblingName: null,
        siblingRollNo: "",
        siblingDiscount: '',
        className: '',
        admissionDeadline: null,
        eligibilityCriteria: []
      },

      userDetails: {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        email: "",
        phoneNo: null,
        password: "",
        role: "",
        membership: "Free",
        userId: "",
        gender: "",
        occupation: "",
        religion: "",
        nationality: "",
        address: "",
        registeredAs: "",
        userDevices: [],
        profile: null,
      },

      enrollmentStatusList: [
        "Active",
        "Inactive",
        "Submit", 
        "In Review",
        "Assessment",
        "Approved", 
        "Reject", 
        "Enrolled"
      ],
      pointOfList: [
       'Father',
       'Mother',
       'Guardian'
      ],
      genderList: [
       'Male',
       'Female'
      ],
      section: 0,
      schoolData: null,
      admissionSelect: 'aOn',
      infrastructure: null,
      academics: null,
      sports: null,
      faculty: null,
      safety: null,
      message: null,
      messageError: null,
      rateError: null,
      admissionDetails: [],
      classList: [],
      academicYearList: [],
      galleryList: [],
      bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"],
      facilitiesURL: process.env.BASE_URL + "facilities",
    };
  },
  computed: {
    schoolItem() {
      return this.$store.getters.SchoolInfoObject;
    },
    getUserName(){
        let userData = secureUI.sessionGet("user");
        return userData.userDetails._id
    },
    checkAdmissionAuvaiable() {
      if(this.admissionDetails.length > 0){
       const statusCheck = this.admissionDetails.filter( x => x.admissionStatus == 'Open' && x.isShowSearch)
       return statusCheck.length
      }else{
        return 0
      }
      
    }
  },
  
  methods: {
     goToSchoolList() {
      this.$store.dispatch("setSchoolInfo", {});
      this.swapComponent("ViParentSchoolList");
    },
    getSchoolInfo() {
      ViService.viGetOnly("/common/details?accountId=" + this.schoolItem._id)
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
              this.schoolData = data;
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
    viewSchoolAction() {
      this.swapComponent("ViParentSchoolList");
    },
    searchSchoolDetails(){

       ViService.viGetOnly("/common/getSearchSchoolFeesDetail?accountId=" + this.schoolItem._id)
        .then((res) => {
          if (res.isSuccess) {
            let data = secureUI.secureGet(res.data);
            if (res.data) {
             this.admissionDetails = data;
            }
          } else {
            this.$toasted.show(res.message);
          }
        })
        .catch((e) => {
          let eData = errorLog.apiErrorLog(e);
          this.$toasted.error(eData);
        });
    },
     async schoolGallaryList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
       // const response = await ViService.viXGet(`/account/galleryList?accountId=${userData.userDetails.account._id}`,userData.token);

        const response = await ViService.viGetOnly("/common/galleryList?accountId=" + this.schoolItem._id);
         

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data); 
          this.galleryList = details.gallery || []
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async submitApplication(){
      let isFormValid = false;

       await this.$validator.validateAll('studentvalidate').then(result => {
          isFormValid = result;
       });

      if (isFormValid) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
      
       ViService.viXPost("/contact/student/parentPortal/apply",this.contactDetails, userData.token)
            .then((res) => {
              if (res.isSuccess) {
                this.$toasted.success(res.message);
                this.$router.push("/parent/admission");
                
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
            });
      }
      }
    },
    applyAdmissionOnline(index){
       let userData = secureUI.sessionGet("user");
       const userinfromation  = userData.userDetails
        this.userDetails.firstName = userinfromation.firstName,
        this.userDetails.email = userinfromation.email || '',
        this.userDetails.phoneNo = userinfromation.phoneNo || '',
        this.userDetails.occupation = userinfromation.occupation || '',
        this.userDetails.religion = userinfromation.religion || '',
        this.userDetails.nationality = userinfromation.nationality || '',
        this.userDetails.address = userinfromation.address || ''
        this.contactDetails.parentId = userinfromation._id || ''

        const classDetails = this.admissionDetails[index]
        
        this.contactDetails.parentId = userinfromation._id || ''
        this.contactDetails.className = classDetails.class ? classDetails.class.className : ''
        this.contactDetails.class = classDetails.class ? classDetails.class._id : ''
        this.contactDetails.academicYear = classDetails.academicYear
        this.contactDetails.accountId = this.schoolItem._id
        this.contactDetails.admissionDeadline = classDetails.admissionDeadline
        this.contactDetails.eligibilityCriteria = classDetails.eligibilityCriteria

        this.$bvModal.show("addstudpop");
    },
    closePoup(){
     this.$bvModal.hide("addstudpop");
     this.clearForm();
    },
    clearForm(){

        this.contactDetails.firstName = "";
        this.contactDetails.lastName = "";
        this.contactDetails.admissionType = "online";
        this.contactDetails.dob = null;
        this.contactDetails.academicYear = null;
        this.contactDetails.gender = "Male";
        this.contactDetails.parentId = null;
        this.contactDetails.accountId = null;
        this.contactDetails.email = "";
        this.contactDetails.mobileOne = 0;
        this.contactDetails.mobileTwo = 0;
        this.contactDetails.image = "";
        this.contactDetails.addressOne = "";
        this.contactDetails.addressTwo = "";
        this.contactDetails.state = "";
        this.contactDetails.district = "";
        this.contactDetails.postalCode = "";
        this.contactDetails.country = "";
        this.contactDetails.classApplied = "";
        this.contactDetails.bloodGroup = null;
        this.contactDetails.admissionStatus = 'Active';
        this.contactDetails.siblings = false;
        this.contactDetails.rollNoAllocated = "";
        this.contactDetails.class = null;
        this.contactDetails.classSection = "";
        this.contactDetails.siblingName = "";
        this.contactDetails.siblingRollNo = "";
        this.contactDetails.siblingDiscount = '';
        this.contactDetails.eligibilityCriteria = [];
        this.userDetails.firstName = "";
        this.userDetails.lastName = "";
        this.userDetails.fatherName = "";
        this.userDetails.motherName = "";
        this.userDetails.email = "";
        this.userDetails.phoneNo = null;
        this.userDetails.password = "";
        this.userDetails.role = "";
        this.userDetails.membership = "Free";
        this.userDetails.userId = "";
        this.userDetails.gender = "";
        this.userDetails.occupation = "";
        this.userDetails.religion = "";
        this.userDetails.nationality = "";
        this.userDetails.address = "";
        this.userDetails.registeredAs = "";
        this.userDetails.userDevices = [];
        this.userDetails.profile = null,
        this.userId = null,
        this.editId = null,
        this.feesNotPaid = false
    },
    setRating(rating, value) {
      value == "infrastructure" ? (this.infrastructure = rating) : null;
      value == "academics" ? (this.academics = rating) : null;
      value == "sports" ? (this.sports = rating) : null;
      value == "faculty" ? (this.faculty = rating) : null;
      value == "safety" ? (this.safety = rating) : null;
    },
    submitRating() {
      let userData = secureUI.sessionGet("user");
      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.$toasted.clear();
   
        if (
          !this.infrastructure ||
          !this.academics ||
          !this.sports ||
          !this.faculty ||
          !this.safety
        ) {
          this.rateError = true;
        } else {
          this.rateError = null;
        }
        if (
          !message &&
          this.infrastructure &&
          this.academics &&
          this.sports &&
          this.faculty &&
          this.safety
        ) {
          let requestBody = {
            accountId: this.schoolItem._id,
            parentId: userData.userDetails._id,
            infrastructure: this.infrastructure,
            academics: this.academics,
            sports: this.sports,
            faculty: this.faculty,
            safety: this.safety,
            message: this.message,
            schoolName: "",
            category: "",
            city: "",
          };
          ViService.viXPost("/rating/school", requestBody, userData.token)
            .then((res) => {
              if (res.isSuccess) {
                this.clearMsg();
                this.$toasted.success("Rating submitted successfully");
              } else {
                this.$toasted.error(res.message);
              }
            })
            .catch((e) => {
              let eData = errorLog.apiErrorLog(e);
              this.$toasted.error(eData);
            });
        }
      }
    },
    clearMsg() {
      this.infrastructure = 0;
      this.academics = 0;
      this.sports = 0;
      this.faculty = 0;
      this.safety = 0;
      this.message = null;
    },
    checkApplied(data){
      if(data){
        const details = data.find( x=> x.parentId == this.getUserName)
        if(details){
            return true
        }else{
           return false
        }
         
      }else{
        return false
      }
    }
  },
  activated() {
    this.getSchoolInfo();
    this.searchSchoolDetails()
    this.schoolGallaryList()
  },
  deactivated() {
    this.schoolData = null;
  },
  filters: {
    averageRating: function(value) {
      if (!value) return "";
      let totalSum = 0;
      for (let key in value) {
        if (value.hasOwnProperty(key)) {
          totalSum += value[key];
        }
      }
      let val = (totalSum / 6).toFixed(1);
      return parseFloat(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.vi-r-review,
.vi-w-review {
  display: inline-block;
}
</style>
