<template>
  <div class="vi-school">
    <keep-alive>
      <component
        :is="currentComponent"
        :swap-component="swapComponent"
      ></component>
    </keep-alive>
  </div>
</template>

<script>
import ViParentSchoolList from "./ViParentSchoolList";
import ViParentSchoolOverview from "./ViParentSchoolOverview";
export default {
  name: "vi-school",
  components: {
    ViParentSchoolList,
    ViParentSchoolOverview,
  },
  data() {
    return {
      currentComponent: "ViParentSchoolList",
    };
  },
  methods: {
    swapComponent: function(component) {
      this.currentComponent = component;
    },
  },
};
</script>

<style lang="scss" scoped></style>
